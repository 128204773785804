

<style>
.custom-option {
  display: flex;
  align-items: center;
}

.option-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Products{{ search }}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->
        <!-- 
        <button
          id="popover-button-bottom"
          href="#"
          tabindex="0"
          type="button"
          class="btn notofication-btn mr-1"
          @click="onPopovcerOpen1()"
        >
          <div>
            <img
              src="../../../assets/images/client/filter.svg"
              alt="Snow"
            />
          </div>
        </button> -->

        <!-- <b-popover
          ref="popover"
          target="popover-button-bottom"
          triggers="click"
          placement="Left"
          class="custom-popover pop-btn"
          :show.sync="popoverShow1"
        >
          <div class="center custom-vs-select" style="border: none">
            <vs-select class="" shadow placeholder="Select" v-model="value">
              <vs-option class="d-none" label="Status" value="1"
                >Status</vs-option
              >
              <vs-option label="All" value="2">All</vs-option>
              <vs-option label="Active" value="3">Active</vs-option>
              <vs-option label="Disabled" value="4">Disabled</vs-option>
              <vs-option label="Inactive" value="5">Inactive</vs-option>
            </vs-select>
          </div>
        </b-popover> -->

        <!-- <button type="button" class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button> -->

        <div v-if="popoverShow" class="popover-manual">
          <div class="popover-inner">
          <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>
          <!-- <button @click="HandelClickMultipleDublicate1()" type="button" class="btn notofication-btn"
            style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button> -->
          <button type="button" class="btn notofication-btn ml-1" @click="HandelClickMultipleEnableDisable()"  v-b-tooltip.hover.v-primary title="Enable-Disable"
            style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn" @click="HandelClickMultipleDelete()"  v-b-tooltip.hover.v-danger title="Delete"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </div>
        <div class="popup-edge">
          </div>
        </div>
        <button id="popover-button-variant" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1"
          v-b-tooltip.hover.v-primary title="Select All"
          @click="onPopovcerOpen()" >
          <div>
            <img id="task1" src="../../../assets/images/client/task-square.svg" alt="Snow" />
          </div>
        </button>


        <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 16px" v-b-modal.modal
          @click="getSequence()">
          +&nbsp; Add Product
        </button>

        <b-modal ref="modal" size="lg" id="modal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:50%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-6">
                <h2>{{edit?'Edit ' :'Add '}} Product</h2>
              </div>
              <div class="form-group col-md-6">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" name="check-button" switch>
                  <span class="switch-icon-left">
                    on
                  </span>
                  <span class="switch-icon-right">
                    off
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="clickAddProduct">
              <div class="form-row mt-1">
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Product Name (English)">
                    <validation-provider #default="{ errors }" name="Product Name (English)" rules="required">
                      <b-form-input v-model="name_en" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Product Name (English)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Product Name (Arabic)">
                    <validation-provider #default="{ errors }" name="Product Name (Arabic)" rules="required">
                      <b-form-input v-model="name_ar" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Product Name (Arabic)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Description (English)">
                    <validation-provider #default="{ errors }" name="Description (English)" rules="required">
                      <quill-editor v-model="descriptionEng" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Description (Arabic)">
                    <validation-provider #default="{ errors }" name="Description (Arabic)" rules="required">
                      <quill-editor v-model="descriptionAr" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Image URL">
                    <validation-provider #default="{ errors }" name="Image URL" rules="required">
                      <b-form-input v-model="imageUrl" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Image URL" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Sequence">
                    <validation-provider #default="{ errors }" name="Sequence" rules="required|integer">
                      <b-form-input v-model="sequence" type="number" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Sequence" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
              <b-form-group class="mb-2" label="Client">
                <validation-provider #default="{ errors }" name="Client" rules="required">
                  <v-select  shadow :options="clientStoreData" placeholder="Select Client" label="name_en"
                    v-model="client" class="cate-vs-select">
                    <template v-slot:option="option">
                      <div style="display: flex;">
                        <strong class="ml-2">{{ option.name_en }}</strong>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Coupon Code">
                    <validation-provider #default="{ errors }" name="Coupon Code" rules="required">
                      <b-form-input v-model="couponCode" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Coupon Code" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Price">
                    <validation-provider #default="{ errors }" name="Price" rules="required|integer">
                      <b-form-input v-model="price" type="number" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Price" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Sale Price">
                    <validation-provider #default="{ errors }" name="Sale Price" rules="required|integer">
                      <b-form-input v-model="salesPrice" type="number" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Sale Price" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Discount Percentage">
                    <validation-provider #default="{ errors }" name="Discount Percentage" rules="required|integer">
                      <b-form-input v-model="discPercentage" type="number" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Discount Percentage" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                <b-form-group class="mb-2" label="Currency">
                <validation-provider #default="{ errors }" name="Currency" rules="required">
                  <v-select  :options="['AED','SAR']" placeholder="Select Currency"
                    v-model="currency" class="cate-vs-select"></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="URL">
                    <validation-provider #default="{ errors }" name="URL" rules="required">
                      <b-form-input v-model="url" :state="errors.length > 0 ? false : null"
                        placeholder="Enter URL" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="modal-footer1">
                <button type="button" class="btn btn-default mr-1" @click="clickAddProduct('addanother')" style="border: 1px solid black">
                  Add Another Product
                </button>
                <button type="button" @click="clickAddProduct" class="btn btn-primary">{{ edit ?
                  'Update' : 'Add' }}</button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <b-modal id="showmodal" ref="showmodal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12  ">
                <h2>Product </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="4">
              <div class="d-flex justify-content-between align-items-center" >
                <div>
                  <div class="mb-half">Image</div>
                  <b-img v-if="checkURL(showItem.image)" v-bind="mainProps" :src="showItem.image"
                    alt="Circle image" class="d-inline-block roundedImage1" />
                  <b-img v-else v-bind="mainProps"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block roundedImage1" />
                </div>
              </div>
            </b-col>
            <b-col md="4">
              <div class="mb-half">Product Name (English)</div>
              <div class="font-weight-bolder text-black ">
                {{ showItem.name_en }}
              </div>
            </b-col>
            <b-col md="4">
              <div class="mb-half">Product Name (Arabic)</div>
              <div class="font-weight-bolder text-black ">
                {{ showItem.name_ar }}
              </div>
            </b-col>
                <b-col md="4" class="d-flex justify-content-between">
                  <div>
                    <div class="mb-half">Coupon Code</div>
                    <div class="font-weight-bolder text-black">
                      <span class="font-weight-bolder h1" style="color: #3f8cff">{{ showItem.coupon_code }}</span>
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="mb-half">Description (English)</div>
                  <p class="font-weight-bolder mt-1" v-html="showItem.desc_en"></p>
                </b-col>
                <b-col md="4">
                  <div class="mb-half">Description (Arabic)</div>
                  <p class="font-weight-bolder" v-html="showItem.desc_ar"></p>
                </b-col>
                <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder ">{{ showItem.sequence }} </div>
              </div>
            </b-col>
                  <b-col md="4">
                  <div>
                    <div class="mb-half">Discount</div>
                    <div class="font-weight-bolder text-black">
                      <span class="font-weight-bolder h2" style="color: red">{{ showItem.discount_percentage }}% OFF</span>
                    </div>
                  </div>
                </b-col>
            <b-col md="4" class="d-flex ">
              <div>
                <div class="mb-half">Client Name</div>
                <p class="font-weight-bolder mt-1">{{ showItem.client_id ? showItem.client_id.name_en : '' }}</p>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Price</div>
                <div class="font-weight-bolder h4" style="color: #2f8cff">{{ showItem.price }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Sales Price</div>
                <div class="font-weight-bolder h4" style="color: #2f8cff">{{ showItem.sale_price }} </div>
              </div>
            </b-col>
           
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Currency</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.currency }}</div>
              </div>
            </b-col>
            <b-col md="4" style="display:inline-block !important" class="d-flex">
              <div>
                <div class="mb-half">URL</div>
                  <a target="_blank" :href="showItem.url" class="font-weight-bolder">{{ showItem.url}}</a>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                <div class="mb-half">Status</div>
                <div class="font-weight-bolder text-black">
                  <span v-if="showItem.status" class="btn btn-outline-success btn-sm">Active</span>
                  <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black"
              @click="$refs.showmodal.hide()">
              Close
            </button>
          </div>
        </b-modal>
      </div>
    </div>

    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="categoriesData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable v-model="categoriesData" @change="draggableChange($event)" handle=".draggable-task-handle" tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in categoriesData"
          @dblclick="handelShow(item)" :key="index">
          <b-row class="p-1">
            <b-col md="2">
              <div class="d-flex align-items-center">
                <div>
                  <b-form-checkbox
                    v-if="popoverShow"
                    v-model="checkBoxIds[item.id]"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @change="CheckBoxHandelClick(item.id)"
                  />
                </div>
                <div class="ml-1 mr-2">
                  <b-img v-if="checkURL(item.image)" v-bind="mainProps" :src="item.image"
                    alt="Circle image" class="d-inline-block roundedImage1" />
                  <b-img v-else v-bind="mainProps"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block roundedImage1" />
                </div>
              </div>
            </b-col>
            <b-col md="10" class="d-flex flex-column">
                <b-row>
                    <b-col md="10" class="mt-2">
                        <div class="d-flex justify-content-between">
                        <div class="font-weight-bolder text-black mb-half">
                            {{item.name_en}}
                        </div>
                        <div class="font-weight-bolder h4 mb-half" style="color: #3f8cff">
                            {{ item.discount_percentage }}% OFF
                        </div>
                    </div>
                    </b-col>
                    
                    <b-col md="2" class="d-flex align-items-center justify-content-center text-black">
                      <div
                      :id="'popover-button-variant'+item.id"
                      href="#"
                        style="background: #f4f9fd; padding: 8px; border-radius: 16px"
                      >
                        <feather-icon icon="MoreVerticalIcon" size="25" />
                      </div>
            </b-col>
                </b-row>
                  <b-popover
                  :target="'popover-button-variant'+item.id"
                  triggers="hover"
                  class="custom-popover"
                >
                  <!-- <button
                    class="notofication-btn"
                    style="color: #408dff; margin: 5px"
                    size="sm"
                  >
                    Cancel
                  </button> -->
                  <button type="button" class="btn notofication-btn ml-1" v-ripple.400="'rgba(186, 191, 199, 0.15)'"  v-b-tooltip.hover.v-primary title="View"
              @click="handelShow(item)" style="background: rgb(125 133 146 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button>
            <button type="button" class="btn notofication-btn ml-1" @click="handelEdit(item.id)" v-b-modal.modal  v-b-tooltip.hover.v-primary title="Edit"
              style="background: rgb(63 140 254 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square"
                viewBox="0 0 16 16">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>
            </button>
            <button type="button" class="btn notofication-btn" @click="handelDelete(item.id)"  v-b-tooltip.hover.v-danger title="Delete"
              style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
              <img src="../../../assets/images/client/delete.png" alt="Snow" />
            </button>
                </b-popover>
                <b-row>
                    <b-col md="2" class="d-flex">
                        <div>
                            <div class="mb-half">Client</div>
                            <div class="font-weight-bolder text-black">{{item.client_id.name_en}}</div>
                        </div>
                    </b-col>
                    <b-col md="2">
                        <div>
                            <div class="mb-half">Price</div>
                            <div class="font-weight-bolder text-black">
                            <span class="font-weight-bolder" style="color: #2f8cff">{{item.price}}</span>
                            </div>
                        </div>
                    </b-col>
                    <b-col md="2" class="d-flex">
                        <div>
                            <div class="mb-half">Sales Price</div>
                            <div class="font-weight-bolder" style="color: #2f8cff">{{item.sale_price}}</div>
                        </div>
                    </b-col>  
                    <b-col md="2" class="d-flex">
                        <div>
                            <div class="mb-half">Currency</div>
                            <div class="font-weight-bolder text-black">{{item.currency}}</div>
                        </div>
                    </b-col>
                    <b-col md="2">
                        <div>
                            <div class="mb-half">Status</div>
                            <div class="font-weight-bolder text-black">
                                <span v-if="item.status" class="btn btn-outline-success btn-sm">Active</span>
                                <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                            </div>
                        </div>
                    </b-col>
                    <b-col md="2" class="d-flex">
                        <div>
                            <div class="mb-half">Sequence</div>
                            <div class="font-weight-bolder text-black">{{item.sequence}}</div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
          </b-row>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !categoriesData.length }"></div>
    </div>

    <button v-if="!isLoading && categoriesData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>
<script>
import draggable from "vuedraggable";
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapState } from 'vuex';
export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    draggable,
  },
  data() {
    return {
      selectedValue: [],
      paginationStart: 0,
      paginationEnd: 10,
      categoriesData: [],
      categoriesData1: [],
      clientStoreData: [],
      data: {},
      required, integer,
      pageNo: 1,
      sequence: 0,
      edit: false,
      paginationStart: 0,
      isLoading: false,
      paginationEnd: 10,
      paginationLength: 0,
      checkBoxIds: {},
      name_en:'',
      name_ar:'',
      descriptionEng:'',
      descriptionAr:'',
      imageUrl:'',
      selectedValue:'',
      couponCode:'',
      price:'',
      client:{},
      salesPrice:'',
      discPercentage:'',
      currency:'',
      url:'',
      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      selectAllLabel: 'Select All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      popoverShow: false,
      status: false,
      selectAll: false,
      showItem: {},
      value: "1",
    };
  },
  mounted() {
    this.isLoading =true
      this.$http.post(`${this.baseURL}/client-categories/brand`).then((res) => {
        this.clientStoreData = res.data.data
        this.clientStoreData1 = res.data.data
      })
  },
  created() { },
  watch: {
   'sidebar': {
     handler: 'checkIsValid',
     immediate: true, 
   },
  '$store.state.application': {
    handler: 'checkIsValid',
    immediate: true,
  },
  },
  computed: {
      ...mapState(['sidebar']),
    search() {
      let search = this.$store.state.searchData
      if (this.$store.state.application) {
        this.getSearch(search);
      }
    },
  },
  methods: {  
    checkIsValid() {
      if (this.sidebar) {
        let is_valid = this.sidebar.some(data => data.name == 'Product');
        if (!is_valid) {
          this.$router.push('/');
        }
      }
    },
    handelShow(item) {
      this.$refs.showmodal.show()
      this.popoverShow = false
      this.showItem = item
    },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },

    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/products/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.categoriesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.categoriesData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    handelEdit(id) {
      this.edit = true

      this.popoverShow = false
      this.$http.post(`${this.baseURL}/products/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
          this.name_en = this.editItem.name_en
          this.name_ar = this.editItem.name_ar
          this.descriptionEng = this.editItem.desc_en
          this.descriptionAr = this.editItem.desc_ar
          this.imageUrl = this.editItem.image
          this.selectedValue = this.editItem.selectedValue
          this.couponCode = this.editItem.coupon_code
          this.price = this.editItem.price
          this.client = this.editItem.client_id
          this.salesPrice = this.editItem.sale_price
          this.discPercentage = this.editItem.discount_percentage
          this.currency = this.editItem.currency
          this.url = this.editItem.url
          this.sequence = parseInt(this.editItem.sequence)
          this.sequence1 = 0
              this.$http.post(`${this.baseURL}/products/get-sequence`).then((json) => {
                console.log(json.data.sequence, "data-=-=--")
                this.sequence1 = json.data.sequence - 1
              })
          this.status = this.editItem.status ? true : false,
            console.log(this.editItem)
        })
      //  this.$router.push(`/roles/editrole/${id}`)
    },
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/products/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.categoriesData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.categoriesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/products/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.categoriesData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.categoriesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
  //  async handelClickFile(e){
  //     const formData = new FormData();
  //     formData.append("image", e);
  //     formData.append("type", 'product');
  //     this.$http.post(`${this.baseURL}/file-upload/create`, formData,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         withCredentials: true
  //       }).then((json) => {
  //         this.imageUrl = json.data.data.image
  //       })
  //   },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/products/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getCategories()
          this.checkBoxIds = {}
          this.$forceUpdate()
        })
    },

    HandelClickMultipleDelete() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.isLoading = true

        this.$http.post(`${this.baseURL}/products/multi-delete`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Deleted",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getCategories()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.selectAllLabel = 'Select All'

            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleEnableDisable() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.isLoading = true

        this.$http.post(`${this.baseURL}/products/multi-enable-disable`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Updated",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getCategories()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.selectAllLabel = 'Select All'

            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleDublicate() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.isLoading = true

        this.$http.post(`${this.baseURL}/products/copy`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            if (res.data.status == 201) {
            
            this.$swal({
            title: "Success",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Success",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getCategories()
          this.selectAllLabel = 'Select All'

          this.checkBoxIds = {}
          this.$forceUpdate()
          } else {
            let text = Object.keys(res.data.data).length > 0 ? Object.keys(res.data.data).map(item => {return `${item}:${res.data.data[item]}`}) : res.data.message
                ? `${res.data.message}` 
                  : "Error"
            this.$swal({
              title: "Error!!",
              text: text,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
        }
      })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    draggableChange(e) {
      console.log(e.moved, "e0-0=-=")
      let search = this.$store.state.searchData
      console.log(this.categoriesData1[e.moved.newIndex].sequence, this.categoriesData1[e.moved.oldIndex].sequence, "e0-0=-=")
      console.log(e.moved.newIndex, e.moved.oldIndex, "e0-0=-=")
      let data = {
        newIndex: this.categoriesData1[e.moved.newIndex].sequence,
        oldIndex: this.categoriesData1[e.moved.oldIndex].sequence,
        pageno: this.pageNo,
        app_id: this.$store.state.application.id
      }
      if (search) { data = { ...data, search: search } }
      this.$http.post(`${this.baseURL}/products/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.categoriesData = []
          this.categoriesData1 = []
          this.categoriesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.categoriesData1 = res.data.data
        })
    },
    getSequence() {
      this.name_en = ''
      this.name_ar = ''
      this.descriptionEng = ''
      this.descriptionAr = ''
      this.imageUrl = ''
      this.selectedValue = ''
      this.couponCode = ''
      this.price = ''
      this.client = {}
      this.salesPrice = ''
      this.discPercentage = ''
      this.currency = ''
      this.url = ''
      this.status = false
      this.edit = false
      this.popoverShow = false
      this.$http.post(`${this.baseURL}/products/get-sequence`).then((json) => {
        this.sequence = json.data.sequence
        this.sequence1 = json.data.sequence
      })
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    clickAddProduct(value) {
      let data = {
        name_en:this.name_en,
        name_ar:this.name_ar,
        coupon_user_id: this.$store.state.application.id,
        status:this.status ? 1 : 0,
        desc_en:this.descriptionEng,
        desc_ar:this.descriptionAr,
        image:this.imageUrl,
        sequence:this.sequence,
        selectedValue:this.selectedValue,
        coupon_code:this.couponCode,
        price:this.price,
        client_id:this.client.id,
        sale_price:this.salesPrice,
        discount_percentage:this.discPercentage,
        currency:this.currency,
        url:this.url,
      }
      if (this.edit) {
        data = { ...{ id: this.editItem.id }, ...data }
      }
      console.log(data)
      console.log(this.sequence, this.sequence1)
      if(this.sequence > this.sequence1){
        this.$swal({
                title: "Warning!!",
                text: `Sequence must be Lower than ${this.sequence1}`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
      }else{
      this.$refs.submitPrevent.validate().then((success) => {
        if(!success){
        this.$swal({
                title: "Warning!!",
                text: `Please Select Require Fields`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
        }else{
        this.$http.post(`${this.baseURL}/products/${this.edit ? 'update' : 'create'}`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 201) {
              this.getCategories()
              this.checkBoxIds = {}
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

              if(value == 'addanother'){
                this.$refs.submitPrevent.reset()
                this.getSequence()
              }
              else{
                this.$refs['modal'].hide()
              }
            } else {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
        }
      })
    }
    },
    CheckBoxHandelClick(id) {
      // this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
      // this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
    },
    selectAllClick() {
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      console.log(data)
      if (this.selectAllLabel == 'Deselect All') {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        // console.log('enter',this.paginationStart,this.paginationEnd,this.categoriesData,this.checkBoxIds)
        // for (let x = this.paginationStart; x < this.paginationEnd; x++) {
        //   this.checkBoxIds[this.categoriesData[x].id] = true
        // }

        this.categoriesData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    async getCategories() {
      if (this.$store.state.application) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/products/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.categoriesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.categoriesData1 = res.data.data
          this.isLoading = false
        })
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  // z-index: 10000;
  position: absolute;
  right: 6.5cm !important;
  background-color: white;
  border-radius: 26px;
  padding: 5px 15px;
  left: auto;
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}

.roundedImage1{
  width:4cm;
  height:4cm;
  border-radius: 25px;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  
}

.modal-body {
    padding: 0px 40px 25px 40px !important;
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
}

textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.cate-vs-select {
  max-width: none;
}
</style>
